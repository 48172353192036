import { Chip } from "@components/controls/Button"
import { MembersOnlyChip } from "@components/controls/MembersOnlyChip"
import { PropsWithChildren } from "react"
import { twMerge } from "tailwind-merge"
import { ChipsScalar } from "../../types.graphql"

type Props = PropsWithChildren<{
  className?: string
  chips: ChipsScalar | undefined | null
  noLinks?: boolean
}>

export function ChipList(props: Props) {
  if (!props.chips) return null
  return (
    <div className={twMerge("flex gap-2 flex-wrap md:gap-1", props.className)}>
      {props.chips.map((chip, i) =>
        chip.membersOnly ? (
          <MembersOnlyChip key={i} />
        ) : (
          <Chip key={i} href={props.noLinks ? undefined : chip.url}>
            {chip.label}
          </Chip>
        ),
      )}
      {props.children}
    </div>
  )
}
