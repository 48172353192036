import { ComponentProps } from "react"
import { Button, Chip } from "./Button"
import { twMerge } from "tailwind-merge"
import { IconLock, IconUnlock } from "@components/svg/Icons"
import { useAuth } from "@features/accounts/hooks/useAuth"
import { useIsSSR } from "eddev/routing"

type Props = {
  iconOnly?: boolean
}

export function MembersOnlyChip(props: ComponentProps<typeof Button>) {
  return (
    <Button variant="charcoal" size="chip" {...props} className={twMerge(props.href ? "" : "!pointer-events-none", props.className)} rightIcon={<MemberLockIcon />}>
      {props.children ?? "Members"}
    </Button>
  )
}

export function MemberLockIcon(props: ComponentProps<typeof IconLock>) {
  const hydrating = useIsSSR()
  const auth = useAuth()
  if (auth.isLoggedIn() && !hydrating) {
    return <IconUnlock {...props} />
  } else {
    return <IconLock {...props} />
  }
}
